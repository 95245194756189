import { Customer } from "./customer.model";
import { Tag } from "./tag.model";
import { USER_NAMAGEMENT_CLASS_NAMES } from "./types.model";
import { User } from "./user.model";
import { Resource } from "@lagoshny/ngx-hateoas-client";

export class Group extends Resource {
  readonly className: string = USER_NAMAGEMENT_CLASS_NAMES.Group;
  id: string;
  name: string;
  description: string;
  users: User[];
  tags: Tag[];
  createdBy: string;
  account: Customer;
  permissions: any[];
  metadata?: { tags: Tag[]; }
}

export type GroupRelatedObject = User;

export class IStateEditGroup {
  group: Group;
}
